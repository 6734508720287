import React from "react";
import imgDefault from "../../assets/image/placeholder.png";

const Block_12 = ({ image, slogan, text }) => {
  return (
    <section className="w-full min-h-screen h-auto relative flex flex-col lg:flex-row my-20">
      <section
        className="w-full lg:w-[55%] min-h-[60vh] h-auto relative bg-cover bg-center before:absolute before:bg-[#00000077] before:w-full before:h-full"
        style={{ backgroundImage: `url("${image ? image : imgDefault}")` }}
      >
        <div className="absolute w-[180px] lg:w-[220px] z-10 -bottom-16 right-[25%] md:right-[35%] lg:bottom-[65%] lg:-right-28">
          <img
            src={
              "https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/Brasha%20LLC%20-%20draw.png?alt=media&token=8ed4653f-25ae-492f-9fa1-28c28a9f556a"
            }
            alt="photo_logo"
            className="w-full bg-white rounded-md"
          />
        </div>
        <div className="w-full h-full relative text-white p-5 pb-20 flex items-center justify-center lg:justify-start text-center">
          <h3>{slogan}</h3>
        </div>
      </section>
      <section className="w-full lg:w-[45%]  h-auto py-20 bg2 flex items-center justify-end text-white">
        <div className="w-4/5 lg:p-10 mx-auto">{text}</div>
      </section>
    </section>
  );
};

export default Block_12;
